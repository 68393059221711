import Config from './config';

export default class LoggerPlugin<T extends {}> extends Config<T> {
  logger: any;
  disabled: boolean;
  constructor(name, defaultConfig = {}) {
    super(name, defaultConfig);
    this.logger = null;
    this.disabled = true;
  }
  configure(data: T) {
    this.config = data;
  }
  register(log): void {
    this.logger = log;
  }
  enable() {
    this.disabled = false;
  }
  disable() {
    this.disabled = true;
  }
}
