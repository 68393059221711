import loglevelPluginRemote from 'loglevel-plugin-remote';
import LoggerPlugin from '../LoggerPlugin';

const defaultConfig = {};
/**
 * @description 將 log 傳送到伺服器
 * @see {@link https://github.com/kutuluk/loglevel-plugin-remote loglevel-plugin-remote}
 */
class LoggerPluginRemote extends LoggerPlugin<any> {
  register(log: any): void {
    super.register(log);
  }
  configure(config) {
    super.configure(config);
    loglevelPluginRemote.disable();
    loglevelPluginRemote.apply(this.logger, this.config);
  }
  enable(): void {
    super.enable();
    loglevelPluginRemote.apply(this.logger, this.config);
  }
  disable(): void {
    super.disable();
    loglevelPluginRemote.disable();
  }
}

export default new LoggerPluginRemote('remote', defaultConfig);
