import LoggerPlugin from '../LoggerPlugin';
import * as loglevelPluginPrefix from 'loglevel-plugin-prefix';

const defaultConfig = {
  template: '[%t] [%l] [%n]',
};
// 因為函式無法序列化存在 localStorage，所以這邊要另外處理
const extraConfig = {
  timestampFormatter(date) {
    return date.toISOString();
  },
  levelFormatter(level) {
    return level.toUpperCase().padEnd(5, ' ');
  },
};
/**
 * @description 將 log 加上前綴
 * @see {@link https://github.com/kutuluk/loglevel-plugin-prefix loglevel-plugin-prefix}
 */
class PrefixPlugin extends LoggerPlugin<loglevelPluginPrefix.LoglevelPluginPrefixOptions> {
  register(log: any): void {
    super.register(log);
    loglevelPluginPrefix.reg(log);
  }
  configure(data: any): void {
    super.configure(data);
    loglevelPluginPrefix.apply(
      this.logger,
      Object.assign({}, extraConfig, this.config),
    );
  }
  enable() {
    super.enable();
    loglevelPluginPrefix.apply(
      this.logger,
      Object.assign({}, extraConfig, this.config),
    );
  }
  disable(): void {
    super.disable();
    loglevelPluginPrefix.apply(this.logger, { template: '' });
  }
}
export default new PrefixPlugin('prefix', defaultConfig);
