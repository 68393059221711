import log from 'loglevel';
import { RootLogger } from 'loglevel';
import LoggerPlugin from './LoggerPlugin.js';
import idb from './plugin/idb.js';
import remote from './plugin/remote.js';
import prefix from './plugin/prefix.js';
import globalError from './plugin/globalError.js';
import loki from './plugin/loki.js';

type PluginNames = 'idb' | 'remote' | 'prefix' | 'globalError' | 'loki';
type PluginList = [
  typeof globalError,
  typeof idb,
  typeof prefix,
  typeof loki,
  typeof remote,
];

// plugin 的順序很重要，先 enable 的後執行
// remote 必須是最後一個，不然不能呼叫 disable
// prefix 會影響到後面的 message 所以往後放
const pluginList: PluginList = [globalError, idb, prefix, loki, remote];
pluginList.forEach((plugin) => {
  plugin.register(log);
});

const plugins = {
  globalError,
  idb,
  prefix,
  loki,
  remote,
};

type ExtendedLogger = RootLogger & {
  plugins: {
    globalError: typeof globalError;
    idb: typeof idb;
    prefix: typeof prefix;
    loki: typeof loki;
    remote: typeof remote;
  };
  pluginList: PluginList;
  configure(name: string, config: any): void;
  enablePlugins(except?: string[]): void;
  disablePlugins(except?: string[]): void;
};
const logger: ExtendedLogger = Object.assign(log, {
  plugins,
  pluginList,
  configure(name: PluginNames, config) {
    const plugin = plugins[name];
    if (!plugin) return;
    plugin.configure(config);
  },
  enablePlugins(except: string[] = []) {
    pluginList.forEach((plugin) => {
      if (except.includes(plugin.name)) return;
      plugin.enable();
    });
  },
  disablePlugins(except: string[] = []) {
    pluginList.forEach((plugin) => {
      if (except.includes(plugin.name)) return;
      plugin.disable();
    });
  },
});
export default logger;
