export default class Config<T extends {}> {
  name: string;
  defaultConfig: T;
  private _config: T;
  static get storageKey() {
    return 'client-logger';
  }
  constructor(name, defaultConfig) {
    this.name = name;
    this.defaultConfig = defaultConfig;
    const savedConfig = this.readConfig();
    if (savedConfig) {
      this._config = savedConfig;
    } else {
      this._config = this.defaultConfig;
      this.saveConfig(this._config);
    }
  }
  set config(data: T) {
    Object.assign(this._config, data);
    this.saveConfig(this._config);
  }
  get config() {
    return this._config;
  }
  private readConfig() {
    const raw = localStorage.getItem(Config.storageKey);
    const allConfig = raw ? JSON.parse(raw) : {};
    return allConfig[this.name];
  }
  private saveConfig(data) {
    const raw = localStorage.getItem(Config.storageKey);
    const allConfig = raw ? JSON.parse(raw) : {};
    allConfig[this.name] = data;
    localStorage.setItem(Config.storageKey, JSON.stringify(allConfig));
  }
}
