import LoggerPlugin from '../LoggerPlugin.js';

type Config = {
  unhandledRejection: boolean;
  error: boolean;
};
const defaultConfig = {
  unhandledRejection: true,
  error: true,
};
/**
 * @description 捕捉應用中的全域錯誤
 * 雖然不一定要當作 plugin 跟 loglevel 結合，但是這樣可以讓錯誤訊息跟其他 log 一起被紀錄
 */
class globalErrorPlugin extends LoggerPlugin<Config> {
  constructor(name, defaultConfig) {
    super(name, defaultConfig);
  }
  onError(event) {
    if (!this.config.error || this.disabled) {
      return;
    }
    event.preventDefault();
    const { message, error, filename, lineno, colno } = event;
    const { stack } = error;
    this.logger.error(
      `[UncaughtError] ${
        stack ? `${stack}` : `${message} ${filename}:${lineno}:${colno}`
      }`,
    );
  }
  onUnhandledRejection(event) {
    if (!this.config.unhandledRejection || this.disabled) {
      return;
    }
    const { reason: error } = event;
    const { message, stack } = error;
    event.preventDefault();
    this.logger.error(
      '[UnhandledRejection] ' + (stack ? `\n${stack}` : message),
    );
  }
  register(log: any): void {
    super.register(log);
    window.addEventListener('error', this.onError.bind(this));
    window.addEventListener(
      'unhandledrejection',
      this.onUnhandledRejection.bind(this),
    );
  }
}

const plugin = new globalErrorPlugin('globalError', defaultConfig);
export default plugin;
