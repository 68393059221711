import { openDB } from 'idb';
import LoggerPlugin from '../LoggerPlugin.js';

type DateType =
  | 'years'
  | 'year'
  | 'y'
  | 'months'
  | 'month'
  | 'm'
  | 'days'
  | 'day'
  | 'd'
  | 'hours'
  | 'hour'
  | 'h'
  | 'minutes'
  | 'minute'
  | 'min'
  | 'seconds'
  | 'second'
  | 'sec';
type Config = {
  autoClean: boolean;
  max: number;
  before: [number, DateType];
};
const defaultConfig: Config = {
  autoClean: true,
  max: 1000,
  before: [7, 'days'],
};
/**
 * @description 將 log 存至 IndexedDB
 */
class IDBPlugin extends LoggerPlugin<Config> {
  dbName: string;
  dbVersion: number;
  storeName: string;
  dbPromise: Promise<any>;
  constructor(name, defaultConfig) {
    super(name, defaultConfig);
    this.dbName = 'musesai-log';
    this.dbVersion = 1;
    this.storeName = 'combined';
  }
  register(log: any): void {
    super.register(log);
    this.dbPromise = openDB(this.dbName, this.dbVersion, {
      upgrade: (upgradeDb) => {
        upgradeDb.createObjectStore(this.storeName, {
          keyPath: 'time',
        });
      },
    });
  }
  enable(): void {
    super.enable();
    const plugin = this;
    const log = this.logger;
    const originalFactory = log.methodFactory;
    log.methodFactory = function (methodName, logLevel, loggerName) {
      const rawMethod = originalFactory(methodName, logLevel, loggerName);
      return (...args) => {
        if (!plugin.disabled) {
          plugin.insert(args, methodName, logLevel, loggerName);
        }
        rawMethod(...args);
      };
    };
    log.setLevel(log.getLevel());
  }
  async insert(args, methodName, logLevel, loggerName) {
    const db = await this.dbPromise;
    const tx = db.transaction(this.storeName, 'readwrite');
    // cast message to transformable type, stripped some properties like function
    const message =
      args.length === 1 && typeof args[0] === 'string'
        ? args[0]
        : JSON.parse(JSON.stringify(args));
    const promises = [
      tx.store.add({
        time: Date.now(),
        loggerName,
        method: methodName,
        message,
      }),
      tx.done,
    ];
    if (this.config.autoClean) {
      if (this.config.max) {
        promises.push(
          this.count().then((count) =>
            this.deleteFirst(count - this.config.max),
          ),
        );
      }
      if (this.config.before) {
        promises.push(this.deleteBefore(this.getTime()));
      }
    }
    return Promise.all(promises);
  }
  async clear() {
    const db = await this.dbPromise;
    const tx = db.transaction(this.storeName, 'readwrite');
    return Promise.all([tx.store.clear(), tx.done]);
  }
  readAll() {
    return this.dbPromise.then((db) => {
      const tx = db.transaction(this.storeName, 'readonly');
      return tx.store.getAll();
    });
  }
  count() {
    return this.dbPromise.then((db) => {
      const tx = db.transaction(this.storeName, 'readonly');
      return tx.store.count();
    });
  }
  async deleteFirst(count) {
    if (count <= 0) {
      return;
    }
    const db = await this.dbPromise;

    const tx = db.transaction(this.storeName, 'readwrite');
    const cursor = await tx.store.openCursor();
    const promises = [tx.done];
    while (promises.length <= count && cursor) {
      promises.push(cursor.delete());
      await cursor.continue();
    }
    return Promise.all(promises);
  }
  async deleteBefore(time) {
    const db = await this.dbPromise;

    const tx = db.transaction(this.storeName, 'readwrite');
    const cursor = await tx.store.openCursor();
    const promises = [tx.done];
    while (cursor) {
      if (cursor.key <= time) {
        promises.push(cursor.delete());
        await cursor.continue();
      } else {
        break;
      }
    }
    return Promise.all(promises);
  }
  getTime() {
    const curr = Date.now();
    if (this.config.before) {
      const [quantity, unit] = this.config.before;
      let multiplier;
      switch (unit.toLowerCase()) {
        case 'years':
        case 'year':
        case 'y':
          multiplier = 365 * 24 * 60 * 60 * 1000;
          break;
        case 'months':
        case 'month':
        case 'm':
          multiplier = 30 * 24 * 60 * 60 * 1000;
          break;
        case 'days':
        case 'day':
        case 'd':
          multiplier = 24 * 60 * 60 * 1000;
          break;
        case 'hours':
        case 'hour':
        case 'h':
          multiplier = 60 * 60 * 1000;
          break;
        case 'minutes':
        case 'minute':
        case 'min':
          multiplier = 60 * 1000;
          break;
        case 'seconds':
        case 'second':
        case 'sec':
          multiplier = 1000;
          break;
        default:
          multiplier = 1;
          break;
      }
      return curr - quantity * multiplier;
    } else {
      return 0;
    }
  }
}

export default new IDBPlugin('idb', defaultConfig);
