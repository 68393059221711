import { LogLevel } from 'loglevel';
import LoggerPlugin from '../LoggerPlugin';

let CIRCULAR_ERROR_MESSAGE;

// https://github.com/nodejs/node/blob/master/lib/util.js
function tryStringify(arg) {
  try {
    return JSON.stringify(arg);
  } catch (error) {
    // Populate the circular error message lazily
    if (!CIRCULAR_ERROR_MESSAGE) {
      try {
        const a = {};
        // @ts-ignore
        a.a = a;
        JSON.stringify(a);
      } catch (circular) {
        CIRCULAR_ERROR_MESSAGE = circular.message;
      }
    }
    if (error.message === CIRCULAR_ERROR_MESSAGE) {
      return '[Circular]';
    }
    throw error;
  }
}

function getConstructorName(obj) {
  if (!Object.getOwnPropertyDescriptor || !Object.getPrototypeOf) {
    return Object.prototype.toString.call(obj).slice(8, -1);
  }

  // https://github.com/nodejs/node/blob/master/lib/internal/util.js
  while (obj) {
    const descriptor = Object.getOwnPropertyDescriptor(obj, 'constructor');
    if (
      descriptor !== undefined &&
      typeof descriptor.value === 'function' &&
      descriptor.value.name !== ''
    ) {
      return descriptor.value.name;
    }

    obj = Object.getPrototypeOf(obj);
  }

  return '';
}

function interpolate(array) {
  let result = '';
  let index = 0;

  if (array.length > 1 && typeof array[0] === 'string') {
    result = array[0].replace(
      /(%?)(%([sdjo]))/g,
      (match, escaped, ptn, flag) => {
        if (!escaped) {
          index += 1;
          const arg = array[index];
          let a = '';
          switch (flag) {
            case 's':
              a += arg;
              break;
            case 'd':
              a += +arg;
              break;
            case 'j':
              a = tryStringify(arg);
              break;
            case 'o': {
              let obj = tryStringify(arg);
              if (obj[0] !== '{' && obj[0] !== '[') {
                obj = `<${obj}>`;
              }
              a = getConstructorName(arg) + obj;
              break;
            }
          }
          return a;
        }
        return match;
      },
    );

    // update escaped %% values
    result = result.replace(/%{2,2}/g, '%');

    index += 1;
  }

  // arguments remaining after formatting
  if (array.length > index) {
    if (result) result += ' ';
    result += array.slice(index).join(' ');
  }

  return result;
}

const { hasOwnProperty } = Object.prototype;

// Light deep Object.assign({}, ...sources)
function assign(...args): any {
  const target = {};
  for (let s = 0; s < args.length; s += 1) {
    const source = Object(args[s]);
    for (const key in source) {
      if (hasOwnProperty.call(source, key)) {
        target[key] =
          typeof source[key] === 'object' && !Array.isArray(source[key])
            ? assign(target[key], source[key])
            : source[key];
      }
    }
  }
  return target;
}

function getStacktrace() {
  try {
    throw new Error();
  } catch (trace) {
    return trace.stack;
  }
}

/**
 * Unix timestamp in nanoseconds
 * e.g. 1686045018004000000
 */
type Timestamp = string;
/**
 * grouping logs with same labels
 */
interface Stream {
  stream: {
    level: keyof LogLevel;
    [key: string]: any;
  };
  values: Array<[Timestamp, string]>;
}
/**
 * Sending multiple streams at once
 */
interface RequestBody {
  streams: Array<Stream>;
}

class MessageQueue {
  pending: Array<Stream>;
  sending: Array<Stream>;
  capacity: number;
  content: string;
  constructor(capacity) {
    this.pending = [];
    this.sending = [];
    this.capacity = capacity;
  }
  push(message: Stream) {
    this.pending.push(message);
    if (this.pending.length > this.capacity) {
      this.pending.shift();
    }
  }
  getRequestBody(): RequestBody {
    if (!this.sending.length) {
      this.sending.push(...this.pending);
      this.pending.length = 0;
    }
    return {
      streams: this.sending,
    };
  }
  onSended() {
    this.sending.length = 0;
    this.content = '';
  }
  onFail() {
    const overflow =
      1 + this.pending.length + this.sending.length - this.capacity;

    if (overflow > 0) {
      this.sending.splice(0, overflow);
      this.pending = this.sending.concat(this.pending);
      this.onSended();
    }
    // if (queue.length + sent.length >= capacity) this.confirm();
  }
}

const hasStacktraceSupport = !!getStacktrace();

function plain(log) {
  return `${log.message}${log.stacktrace ? `\n${log.stacktrace}` : ''}`;
}
type Config = {
  url: string;
  method: string;
  headers: object;
  token?: string;
  timeout: number;
  interval: number;
  level: keyof LogLevel | Lowercase<keyof LogLevel>;
  backoff: {
    multiplier: number;
    jitter: number;
    limit: number;
  };
  capacity: number;
  stacktrace: {
    levels: string[];
    depth: number;
    excess: number;
  };
  exclude: Array<string | RegExp>;
  excludeLocal: boolean;
};
const defaultConfig: Config = {
  url: '/loki/api/v1/push',
  method: 'POST',
  headers: {},
  token: '',
  timeout: 0,
  interval: 1000,
  level: 'trace',
  backoff: {
    multiplier: 2,
    jitter: 0.1,
    limit: 30000,
  },
  capacity: 500,
  stacktrace: {
    levels: ['trace', 'warn', 'error'],
    depth: 3,
    excess: 0,
  },
  exclude: [],
  excludeLocal: true,
};
/**
 * @description 將 log 傳送到 loki 伺服器
 * @fork {@link https://github.com/kutuluk/loglevel-plugin-remote loglevel-plugin-remote}
 */
class LoggerPluginLoki extends LoggerPlugin<Config> {
  contentType: string;
  isSending: boolean;
  isSuspended: boolean;
  queue: MessageQueue;
  #labels: object;
  interval: number;
  constructor(name, defaultConfig = {}) {
    super(name, defaultConfig);
    this.contentType = 'application/json';
    this.isSending = false;
    this.isSuspended = false;
    this.queue = new MessageQueue(this.config.capacity);
    this.interval = 1000;
    this.#labels = {};
  }
  register(logger: any): void {
    super.register(logger);

    if (!logger || !logger.getLogger) {
      throw new TypeError('Argument is not a root loglevel object');
    }
  }
  enable(): void {
    super.enable();

    const { config, logger } = this;

    const originalFactory = logger.methodFactory;

    logger.methodFactory = (methodName, logLevel, loggerName) => {
      const rawMethod = originalFactory(methodName, logLevel, loggerName);
      const needStack =
        hasStacktraceSupport &&
        config.stacktrace.levels.some((level) => level === methodName);
      const levelVal = logger.levels[methodName.toUpperCase()];
      const needLog = levelVal >= logger.levels[config.level.toUpperCase()];
      const self = this;
      return (...args) => {
        const isLocal = config.excludeLocal
          ? ['127.0.0.1', 'localhost'].includes(location.hostname)
          : false;
        const isExcluded = config.exclude.some((pattern) => {
          if (typeof pattern === 'string') {
            return pattern === location.hostname;
          }
          if (pattern instanceof RegExp) {
            return pattern.test(location.hostname);
          }
          return false;
        });
        if (needLog && !self.disabled && !isLocal && !isExcluded) {
          const timestamp: Timestamp = Date.now().toString() + '000000';

          let stacktrace = needStack ? getStacktrace() : '';
          if (stacktrace) {
            const lines = stacktrace.split('\n');
            lines.splice(0, config.stacktrace.excess + 3);
            const { depth } = config.stacktrace;
            if (depth && lines.length !== depth + 1) {
              const shrink = lines.splice(0, depth);
              stacktrace = shrink.join('\n');
              if (lines.length) stacktrace += `\n    and ${lines.length} more`;
            } else {
              stacktrace = lines.join('\n');
            }
          }

          const log = plain({
            message: interpolate(args),
            level: {
              label: methodName,
              value: levelVal,
            },
            logger: loggerName || '',
            timestamp: new Date().toISOString(),
            stacktrace,
          });
          const stream: Stream = {
            stream: {
              level: methodName.toUpperCase(),
              logger: loggerName || '',
              ...self.#labels,
            },
            values: [[timestamp, log]],
          };

          self.queue.push(stream);
          self.send();
        }

        rawMethod(...args);
      };
    };
    logger.setLevel(logger.getLevel());

    return logger;
  }
  send() {
    if (this.isSuspended || this.isSending || this.config.token === undefined) {
      return;
    }

    if (!this.queue.sending.length) {
      if (!this.queue.pending.length) {
        return;
      }

      this.queue.content = JSON.stringify(this.queue.getRequestBody());
    }

    this.isSending = true;
    const { config } = this;
    const xhr = new XMLHttpRequest();
    xhr.open(config.method, config.url, true);
    xhr.setRequestHeader('Content-Type', this.contentType);
    if (config.token) {
      xhr.setRequestHeader('Authorization', `Bearer ${config.token}`);
    }

    const { headers } = config;
    for (const header in headers) {
      if (hasOwnProperty.call(headers, header)) {
        const value = headers[header];
        if (value) {
          xhr.setRequestHeader(header, value);
        }
      }
    }

    let timeout;
    if (config.timeout) {
      timeout = setTimeout(() => {
        this.isSending = false;
        xhr.abort();
        this.suspend();
      }, config.timeout);
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) {
        return;
      }

      this.isSending = false;
      clearTimeout(timeout);

      if (xhr.status === 200 || xhr.status === 204) {
        // eslint-disable-next-line prefer-destructuring
        this.interval = config.interval;
        this.queue.onSended();
        this.suspend(true);
      } else {
        if (xhr.status === 401) {
          const { token } = config;
          config.token = undefined;
          // config.onUnauthorized(token);
        }
        this.suspend();
      }
    };

    xhr.send(this.queue.content);
  }
  suspend(successful = false) {
    if (!successful) {
      this.interval = this.getNextInterval();
      this.queue.onFail();
    }

    this.isSuspended = true;
    setTimeout(() => {
      this.isSuspended = false;
      this.send();
    }, this.interval);
  }
  /**
   * 使用遞增的方式計算下一次的間隔時間
   * @returns {number} next interval
   */
  getNextInterval() {
    const setting = this.config.backoff;
    const { multiplier, limit, jitter } = setting;
    const func =
      typeof setting === 'object'
        ? (duration) => {
            let next = duration * multiplier;
            if (next > limit) next = limit;
            next += next * jitter * Math.random();
            return next;
          }
        : setting;
    return func(this.interval);
  }
  set labels(labels) {
    if (typeof labels !== 'object') {
      this.#labels = {};
    } else {
      this.#labels = labels;
    }
  }
  get labels() {
    return this.#labels;
  }
}

export default new LoggerPluginLoki('loki', defaultConfig);
